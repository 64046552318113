@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap);

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

/*             base styles                  */

:root {
  --base-color: #32064a;
  --base-color-light: #8e2bc4;
  --base-color-light-f-15: #8e2bc426;
  --base-color-light-hover: #32064a26;
  --base-color-disabled: #bb8cd3;
  --base-color-hover: #7e1bb4;
  --base-color-active: #360052;
  --base-color-outline: #6901a06c;
  --base-color-deprecated-bg: #bb89d5;
  --base-color-deprecated-border: #8c3ab8;
  --base-1: #e0dae4;
  --base-2: #d7c6df;
  --base-3: #bb89d5;
  --base-4: #7a1aae;
  --base-color-active-deprecated-f-30: #dbc8e64e;
  --base-color-active-deprecated-d-02: #d5c5de;
  --base-color-separators: 50, 6, 74;
  --base-color-light-separators: 142, 43, 196;
  --base-color-light-f-15-separators: 142, 43, 196, 0.149;
  --base-color-light-hover-separators: 50, 6, 74, 0.149;
  --base-color-disabled-separators: 187, 140, 211;
  --base-color-hover-separators: 126, 27, 180;
  --base-color-active-separators: 54, 0, 82;
  --base-color-outline-separators: 105, 1, 160, 0.424;
  --base-color-deprecated-bg-separators: 187, 137, 213;
  --base-color-deprecated-border-separators: 140, 58, 184;
  --base-1-separators: 224, 218, 228;
  --base-2-separators: 215, 198, 223;
  --base-3-separators: 187, 137, 213;
  --base-4-separators: 122, 26, 174;
  --base-color-active-deprecated-f-30-separators: 219, 200, 230, 0.306;
  --base-color-active-deprecated-d-02-separators: 213, 197, 222;
}

body {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
  min-width: 350px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--base-color-light-hover);
  }
}

#root {
  display: flex;
  flex-direction: column;
}

::selection {
  @apply bg-base-color-light text-white;
}

/*             base styles                  */

/*             global styles                  */

.with-max-width {
  @apply max-w-[2500px] mx-auto #{!important};
}

a:not(.no-underline) {
  background-image: linear-gradient(90deg, var(--base-3), var(--base-4));
  background-repeat: no-repeat;
  background-size: 0% 10%;
  background-position: bottom left;
  transition: background-size 0.3s linear;
}

a:not(.no-underline):hover {
  background-size: 100% 10%;
}

a.simple-link:not(.no-underline) {
  background: initial;
}

a.simple-link:not(.no-underline):hover {
  @apply underline;
}

.SnackbarContent-root {
  @apply max-w-[800px] flex-nowrap #{!important};
}

.SnackbarItem-message {
  @apply font-bold;
}

.circular-progress-box {
  @apply min-w-[200px] flex items-center justify-center;
}

.MuiFab-action.Mui-disabled {
  @apply border-gray-400;
}

.MuiChip-deleteIcon {
  font-size: 18px !important;
}

.select-box-end-adornment-button {
  @apply absolute right-6;
}

.end-adornment-close-button {
  @apply text-[22px] leading-5;
}

#notistack-snackbar {
  @apply w-[calc(100%-40px)] relative;
}

.lead-comment-types-menu {
  .lead-comment-type-item {
    @apply text-sm leading-4;
  }
}

.lead-comment-field-menu {
  .lead-comment-field-item {
    @apply text-sm leading-4 mx-2 rounded-md;
    &:not(:last-child) {
      @apply mb-1;
    }
  }

  .lead-comment-field-search-input {
    @apply h-[30px] w-[200px] max-w-[250px] relative;
    .MuiInputBase-input {
      @apply py-1.5 pl-2;
    }
  }
}

/*             global styles                  */

/*             aside-container style                  */

.aside-container {
  @apply z-[100] sm:w-[220px] w-full fixed h-screen transition-[transform] translate-x-0 duration-300 bg-white;

  /*             sidebar-panel-wrapper style                  */

  .sidebar-panel-wrapper {
    @apply overflow-x-hidden flex flex-col;
    .sidebar-panel-header {
      .logo-name {
        @apply xl:text-lg lg:text-base text-xs font-medium pl-2;
      }

      .boss-title {
        @apply select-none text-center font-medium mt-1 text-base-color-light text-lg;
        letter-spacing: 4px;
      }
    }

    .sidebar-links-wrapper {
      &:first-of-type {
        @apply flex-auto;
      }

      .navigation-button {
        @apply relative;
        .navigation-link-bottom {
          @apply py-2 px-2.5 rounded-[6px] text-black text-[0.85rem] cursor-pointer relative min-h-[32px] h-[32px] w-full transition-[width] duration-300 leading-5 flex items-center;

          &::before {
            content: '';
            @apply flex items-center rounded-[6px] absolute top-0 left-0 w-0 h-full bg-gradient-to-br from-base-color to-base-color-light transition-[width] duration-300;
          }

          .link-icon {
            @apply mr-[0.3rem] text-[18px] w-[18px] transition-[color] duration-300;
          }
          .link-sub-icon {
            @apply absolute text-sm bottom-2 left-[25px] bg-transparent rounded flex transition-[color] duration-300;
          }
        }
        .navigation-link-top {
          @apply opacity-0 absolute py-2 px-2.5 rounded-[6px] text-[0.85rem] cursor-pointer min-h-[32px] h-[32px] w-full transition-[opacity] duration-300 leading-5 flex items-center;
          span {
            @apply text-white #{!important};
          }

          .link-icon {
            @apply mr-[0.3rem] text-[18px] w-[18px] transition-[color] duration-300 text-white;
          }
          .link-sub-icon {
            @apply absolute text-sm bottom-2 left-[25px] bg-transparent flex transition-[color] duration-300 text-white;
          }
        }

        &.active-link,
        &:hover {
          .navigation-link-bottom {
            &::before {
              @apply w-full;
            }
          }
          .navigation-link-top {
            @apply opacity-100;
          }
        }
      }

      .navigation-link {
        @apply py-2 px-2.5 rounded-lg text-black text-lg cursor-pointer relative min-h-[40px] h-[40px] w-full transition-[width] duration-300 leading-5 flex items-center;

        &::before {
          content: '';
          @apply flex items-center rounded-lg absolute top-0 left-0 w-0 h-full bg-gradient-to-br from-base-color to-base-color-light transition-[width] duration-300;
        }

        &::after {
          content: attr(data-text);
          @apply rounded-lg absolute top-0 flex items-center h-full bg-transparent transition-[opacity] duration-300 opacity-0 ml-5 px-2;
        }

        &.active-link,
        &:hover {
          &::before {
            @apply text-white w-full;
          }
          &::after {
            @apply text-white opacity-100;
          }
          .link-icon {
            @apply text-white;
          }
        }

        .link-icon {
          @apply mr-[0.3rem] text-2xl w-[24px] z-10 transition-[color] duration-300;
        }
      }

      .navigation-link-accordion {
        @apply w-full;
        .MuiAccordionSummary-root {
          @apply py-2 px-2.5 rounded-lg text-black text-[0.9rem] cursor-pointer relative min-h-[40px] h-[40px] w-full transition-[width] duration-300 leading-5;

          &::before {
            content: '';
            @apply flex items-center rounded-lg absolute top-0 left-0 w-0 h-full bg-gradient-to-br from-base-color to-base-color-light transition-[width] duration-300;
          }

          &::after {
            content: attr(data-text);
            @apply rounded-lg absolute top-0 flex items-center left-0 h-full bg-transparent transition-[opacity] duration-300 opacity-0 ml-8 px-2;
          }

          &:hover {
            &::before {
              @apply text-white w-full;
            }
            &::after {
              @apply -top-[1px] text-white opacity-100;
            }
            .accordion-expand-icon,
            .link-icon {
              @apply text-white;
            }
          }

          .accordion-expand-icon {
            @apply relative text-black transition-[color] duration-300;
          }

          .link-icon {
            @apply mr-[0.4rem] flex items-center h-8 text-xl w-[24px] z-10 transition-[color] duration-300;
          }
        }

        &.active-link {
          .MuiAccordionSummary-root {
            &::before {
              @apply text-white w-full;
            }
            &::after {
              @apply text-white opacity-100;
            }
            .accordion-expand-icon,
            .link-icon {
              @apply text-white;
            }
          }
        }

        .navigation-link-accordion-list-wrapper {
          .navigation-link {
            @apply py-2 px-2.5 rounded-lg text-black text-lg text-start cursor-pointer relative min-h-[40px] w-full transition-[width] duration-300 leading-5 flex items-center;

            &::before {
              content: '';
              @apply flex items-center rounded-lg absolute top-0 left-0 w-0 h-full bg-gradient-to-br from-base-color to-base-color-light transition-[width] duration-300;
            }

            &::after {
              content: attr(data-text);
              @apply rounded-lg absolute top-0 flex items-center w-[calc(100%-20px)] h-full bg-transparent transition-[opacity] duration-300 opacity-0 ml-0 px-0;
            }

            &.active-link,
            &:hover {
              &::before {
                @apply text-white w-full;
              }
              &::after {
                @apply text-white opacity-100;
              }
            }
          }
        }
      }

      li {
        @apply min-h-[34px] flex items-center justify-between my-1;
      }
    }

    .toggle-btn-wrapper {
      @apply transition-[right] sm:flex hidden -right-5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--base-color-light);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--base-color);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: var(--white-color);
    }
  }

  /*             sidebar-panel-wrapper style                  */

  .toggle-btn-wrapper {
    @apply absolute top-1/2;

    // .toggle-btn-icon:not(:hover) {
    // 	@apply text-base-color text-xl leading-5;
    // }
  }

  &.sidebar-small {
    @apply -translate-x-full transition-[transform] duration-300;
    .toggle-btn-wrapper {
      @apply flex -right-8;
    }
  }
}

/*             aside-container style                  */

/*             base-container style                  */

.base-container {
  @apply sm:w-[calc(100%-220px)] transition-[width] duration-300 h-screen ml-auto;
  &.base-container-full {
    @apply w-full;
  }
}

/*             base-container style                  */

/*             header-container style                  */

.header-container {
  .top-panel-wrapper {
    .top-panel-tools {
      .account {
        @apply flex flex-row items-center;
        .user-avatar-box {
          @apply font-extrabold text-2xl;
        }
        .avatar-image {
          @apply object-cover w-[40px] h-[40px] outline outline-base-color-light outline-2 rounded-lg cursor-pointer;
        }
        .avatar-name {
          @apply w-[40px] h-[40px] px-1 border-base-color-light border-2 rounded-full cursor-pointer uppercase text-xl flex items-center justify-center font-semibold text-white bg-gradient-to-br from-base-color to-base-color-light transition-all duration-300 overflow-hidden;
          &:hover {
            @apply bg-gradient-to-br from-base-color-light to-base-color-light;
          }
        }
        .user-full-name-box {
          @apply flex flex-col leading-[14px] ml-2 normal-case text-base items-start;
        }
      }
    }
  }
}

/*             header-container style                  */

/*             main-container style                  */

.main-container {
  @apply h-[calc(100vh-4rem)] w-full px-4 py-2;
}

/*             main-container style                  */

/*             toggle-btn-sign style                  */

.toggle-btn-sign {
  @apply bg-white p-0.5 text-base-color text-lg rounded-sm;
}

/*             toggle-btn-sign style                  */

/*             upload-btn style                  */

.upload-btn {
  @apply w-full;
  &.uploaded {
    @apply text-base h-[40px] mr-2 text-line-1;
  }

  &.field-required-error {
    @apply bg-red-600 text-white border-red-600;
    &:hover {
      @apply bg-red-500;
    }
  }

  &.Mui-disabled {
    @apply border-gray-400;
  }
}

/*             upload-btn style                  */

/*             close-btn-wrapper style                  */

.close-btn-wrapper {
  @apply top-[5px] right-[5px] absolute;
}

/*             close-btn-wrapper style                  */

/*             no-data-found-wrapper style                  */

.no-data-found-wrapper {
  @apply text-gray-400 flex items-center justify-center select-none pointer-events-none;
  &.select-box,
  &.small-size {
    @apply text-sm leading-4 h-8;
  }

  &.left-side {
    @apply justify-start;
  }

  .icon-lg {
    @apply text-lg flex items-center mr-1;
  }

  .icon-xl {
    @apply text-xl flex items-center leading-4 mr-1;
  }
}

/*             no-data-found-wrapper style                  */

/*             language-menu style                  */

.language-menu {
  .language-menu-item {
    @apply text-base leading-4 font-normal h-10 justify-center;
    &.active-link {
      @apply bg-base-color text-white;
    }
  }
}

/*             language-menu style                  */

/*             daily-currency-wrapper style                  */

.daily-currency-wrapper {
  @apply text-sm leading-4 bg-base-color-light-f-15 rounded-lg h-10 p-2 pt-1 text-center whitespace-nowrap cursor-pointer;
  .daily-currency-title {
    @apply text-[10px] leading-3 mb-0.5;
  }
  span {
    @apply font-medium tracking-widest;
  }
}

/*             daily-currency-wrapper style                  */

/*             calls-history-drawer-wrapper style                  */

.calls-history-drawer-wrapper {
  @apply w-[50vw] flex flex-col p-6 overflow-hidden;
  .drawer-title {
    @apply text-lg font-medium mb-2;
  }
  .drawer-body {
    .calls-history-tabs-wrapper {
      .calls-history-tab-item {
        @apply mt-3;
        .tab-table-container {
          @apply h-[calc(100vh-140px)] overflow-y-auto;
        }
      }
    }
  }
}

/*             calls-history-drawer-wrapper style                  */

/*             component-title style                  */

.component-title {
  @apply text-base-color text-lg font-medium;
}

/*             component-title style                  */

/*             component-list-wrapper style                  */

.component-list-wrapper {
  @apply flex flex-col h-full;
}

/*             component-list-wrapper style                  */

/*             notification-menu-wrapper style                  */

.notification-menu-wrapper {
}

.notification-menu {
  .notification-menu-item {
    @apply flex flex-col items-start cursor-default px-4 py-2 m-2 rounded-lg;

    &.not-read {
      @apply bg-base-color text-white;
      &:hover {
        background-color: rgba(var(--base-color-separators), 0.8);
      }
    }

    &:hover {
      background-color: rgba(var(--base-color-light-separators), 0.15);
    }
  }

  .MuiMenu-paper {
    @apply max-h-[300px] overflow-y-auto;
    .MuiMenu-list {
      @apply max-w-[330px] p-0 w-full #{!important};
    }
  }
}

/*             notification-menu-wrapper style                  */

/*             notification-item-wrapper style                  */

.notification-item-wrapper {
  @apply my-shadow-2 p-4 w-full rounded-lg;
  .notification-item-header {
    .notification-item-title {
      @apply text-3xl font-medium;
    }
  }

  .notification-item-body {
    p {
    }
  }
}

/*             notification-item-wrapper style                  */

/*             base-table style                  */

.base-table {
  .MuiTableBody-root {
    .MuiTableRow-root {
      @apply relative;
      &:hover {
        background-color: rgba(var(--base-color-light-separators), 0.15);
        &::after {
          content: '';
          @apply absolute left-0 w-[4px] h-full bg-base-color;
        }
      }

      &.is-completed {
        @apply bg-cyan-200 hover:bg-cyan-400;
      }

      &.is-cancelled {
        @apply bg-red-300 hover:bg-red-400;
      }
    }
  }
}

.animate-spin {
  @apply flex items-center justify-center;
}

/*             base-table style                  */

/*             blocks-slider-wrapper style                  */

.blocks-slider-wrapper {
  @apply mx-4;
  .slick-slider {
    @apply -mb-1;
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            @apply m-2 flex items-center justify-center;
          }
          .block-item {
            @apply my-shadow-2 rounded-lg h-[100px] transition-colors;
            .block-title {
              @apply text-xl font-medium text-base-color text-line-1 w-full text-left;
            }
            &.item-selected,
            &:hover {
              @apply bg-gradient-to-br from-base-color to-base-color-light text-white;
              .block-title {
                @apply text-white;
              }
            }
          }
        }
      }
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        @apply w-[40px] h-[40px] text-base-color my-shadow-1 bg-white rounded-full z-10 transition-colors;

        &.slick-disabled {
          &::before,
          &::after {
            @apply bg-base-color-disabled opacity-100;
          }
          &:hover {
            &.slick-prev,
            &.slick-next {
              @apply bg-base-color-disabled;
            }

            &.slick-prev::before,
            &.slick-prev::after,
            &.slick-next::before,
            &.slick-next::after {
              @apply bg-white;
            }
          }
        }
      }

      &.slick-prev {
        &::before,
        &::after {
          content: '';
          @apply absolute w-2.5 h-[2px] bg-base-color rounded-md;
        }
        &::before {
          @apply -translate-y-[3px] -rotate-45 -translate-x-1.5;
        }
        &::after {
          @apply translate-y-[3px] rotate-45 -translate-x-1.5;
        }
      }

      &.slick-next {
        &::before,
        &::after {
          content: '';
          @apply absolute w-2.5 h-[2px] bg-base-color rounded-md;
        }
        &::before {
          @apply -translate-y-[3px] rotate-45 -translate-x-1;
        }
        &::after {
          @apply translate-y-[3px] -rotate-45 -translate-x-1;
        }
      }

      &:hover {
        &.slick-prev,
        &.slick-next {
          @apply bg-base-color;
        }

        &.slick-prev::before,
        &.slick-prev::after,
        &.slick-next::before,
        &.slick-next::after {
          @apply bg-white;
        }
      }
    }
  }
}

/*             blocks-slider-wrapper style                  */

/*             dashboard-objects-slider-wrapper style                  */

.dashboard-objects-slider-wrapper {
  @apply -mx-2;
  .swiper {
    @apply p-2;
    .object-item {
      @apply my-shadow-2 rounded-lg h-[120px] w-full transition-colors border-2 border-solid border-transparent;
      .object-item-body {
        @apply flex flex-col justify-start items-start p-2 w-full;
        .object-title {
          @apply text-lg leading-7 font-medium text-base-color w-full text-left;
        }
        .object-home-type-number {
          @apply text-[12px] text-left;
        }
      }
      .object-home-type-indicator {
        @apply h-2 w-full my-shadow-1 rounded my-1 flex items-center overflow-hidden;
        .home-type-indicator {
          @apply h-full;

          &.sold {
            @apply bg-green-600;
            width: var(--indicator-width, 0);
          }

          &.order {
            @apply bg-orange-400;
            width: var(--indicator-width, 0);
          }
        }
      }
      &.item-selected,
      &:hover {
        @apply bg-base-color-light-f-15 border-2 border-solid border-base-color-light;
      }
    }

    .swiper-pagination {
      @apply -bottom-2;
      .swiper-pagination-bullet {
        @apply bg-base-color-light;
      }
    }
  }
}

/*             dashboard-objects-slider-wrapper style                  */

/*             dashboard-filter-drawer-wrapper style                  */

.dashboard-filter-drawer-wrapper {
  @apply w-[250px] flex flex-col p-6 overflow-auto;

  .dashboard-filter-accordion {
    @apply mb-4;
    .dashboard-filter-accordion-summary {
      @apply my-shadow-1 px-2 min-h-[32px] h-8;
      .expand-icon {
        @apply text-sm text-base-color-light;
      }
      .filter-icon {
        @apply text-base text-base-color-light mr-1;
      }
      .accordion-title {
        @apply text-sm leading-4 text-base-color;
      }
    }
    .dashboard-filter-accordion-details {
      @apply px-0;
    }

    &::before {
      content: '';
      display: none;
    }

    &.Mui-expanded {
      @apply m-0;
    }
  }

  .home-stages-wrapper {
    @apply -mx-1 flex items-center flex-wrap;
    .stage-item {
      @apply w-8 h-8 min-w-[32px] min-h-[32px] m-[5px] text-sm leading-3;
      .item-selected {
        @apply bg-base-color-light;
      }
    }
  }

  .home-rooms-wrapper {
    @apply -mx-1 flex items-center flex-wrap;
    .room-item {
      @apply h-8 min-w-[32px] min-h-[32px] max-w-min px-3 m-[5px] text-sm leading-3;
      .item-selected {
        @apply bg-base-color-light;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--base-color-light-hover);
  }
}

/*             dashboard-filter-drawer-wrapper style                  */

/*             sheet-wrapper style                  */

.sheet-wrapper {
  @apply flex flex-row h-full overflow-y-auto;

  .sheet-base-area {
    @apply w-full flex items-center justify-center;

    .sheet-grid {
      // @apply bg-base-color-light-hover rounded-lg px-4 pb-4 h-full w-full overflow-auto flex;
      @apply bg-base-color-light-hover rounded-lg px-4 pb-4 h-[calc(100vh-124px)] w-full overflow-auto flex;
      // @apply bg-base-color-light-hover rounded-lg px-4 h-[max-content] w-full overflow-auto flex;

      .sheet-column {
        .sheet-row {
          @apply flex items-center w-fit px-3 pb-2 rounded-lg;
          .sheet-home-row {
            @apply my-0.5 flex-nowrap;
            .sheet-home-cell {
              @apply min-w-[130px] mx-1 relative;
              .home-item {
                @apply max-w-[130px] w-full h-full transition-[scale,opacity] p-2 my-shadow-1 rounded flex flex-col tracking-[-0.3px] bg-white;
                &.is-empty {
                  @apply h-[76px] opacity-0 select-none pointer-events-none cursor-default;
                }
                &.is-disabled {
                  @apply transition-[scale,opacity] opacity-40 scale-90;
                }
                &:hover {
                  @apply bg-base-color-deprecated-bg;
                }
                &.item-selected {
                  @apply bg-base-color my-shadow-1 text-white;
                }
                .home-item-data {
                  @apply flex items-center justify-between w-full my-1 text-[0.785rem] leading-3;
                }

                &.status-2 {
                  @apply bg-orange-400;
                  &:hover {
                    @apply bg-orange-500;
                  }
                  &.item-selected {
                    @apply bg-orange-600 my-shadow-1 text-white border-solid border-2 border-base-color outline outline-2 outline-base-color;
                  }
                }

                &.status-3 {
                  @apply bg-red-500 text-white;
                  &:hover {
                    @apply bg-red-600;
                  }
                  &.item-selected {
                    @apply bg-red-600 my-shadow-1 text-white border-solid border-2 border-base-color outline outline-2 outline-base-color;
                  }
                }

                &.status-4 {
                  @apply bg-gray-500 text-white;
                  &:hover {
                    @apply bg-gray-700;
                  }
                  &.item-selected {
                    @apply bg-gray-700 my-shadow-1 text-white border-solid border-2 border-base-color outline outline-2 outline-base-color;
                  }
                }
              }
            }
          }

          &:hover {
            @apply bg-base-color-light-hover;
          }
          &:has(.sheet-home-row > .sheet-home-empty-cell) {
            @apply opacity-0 select-none pointer-events-none;
          }
        }
      }

      /* .sheet-home-floor {
				@apply min-w-[20px] text-sm font-medium;
				.sheet-home-floor-item {
					@apply h-[112px];
				}
			} */

      .sheet-home-floor {
        @apply min-w-[20px] text-sm font-medium mt-[8px] mr-2;
        .sheet-home-floor-item-wrapper {
          .sheet-home-floor-item {
            @apply h-[76px] flex items-center cursor-default select-none pointer-events-none;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--base-color-light);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--base-color);
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: var(--white-color);
      }
    }

    .sheet-grid > .sheet-column {
      @apply ml-0;
    }
    .sheet-grid > .sheet-column ~ .sheet-column {
      @apply ml-16;
    }
  }

  &.type-2 {
    // @apply h-auto;

    .sheet-base-area {
      .sheet-grid {
        .sheet-column {
          @apply h-[max-content] pb-3;
          .sheet-row {
            @apply px-3 py-0.5 rounded;
            .sheet-home-row {
              .sheet-home-cell {
                @apply min-w-[30px] min-h-[30px] mx-1;
                .home-item {
                  @apply max-w-[30px] h-[30px] text-[12px] leading-3 relative;
                  &.is-empty {
                    @apply h-[30px] relative;
                  }
                }
              }
            }
            &:hover {
              @apply bg-base-color-light-hover;
            }
            &:has(.sheet-home-row > .sheet-home-empty-cell) {
              @apply opacity-0 select-none pointer-events-none;
            }
          }
        }
        .sheet-home-floor {
          @apply min-w-[20px] text-sm font-medium mt-[2px] mr-2;
          .sheet-home-floor-item {
            @apply max-w-[30px] h-[30px] cursor-default select-none pointer-events-none;
          }
        }
      }
    }
  }
}

/*             sheet-wrapper style                  */

/*             sheet-filter-wrapper style                  */

.sheet-filter-wrapper {
  @apply -translate-x-[290px] relative w-0 opacity-0 transition-[transform,opacity] duration-300;
  &.is-full {
    @apply translate-x-0 w-[290px] min-h-[calc(100vh-120px)] opacity-100 mr-4;

    .sheet-filter-body {
      @apply w-full h-[calc(100vh-80px)] overflow-y-auto overflow-x-hidden my-shadow-2 rounded-lg py-10 top-4;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--base-color-light);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--base-color);
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: var(--white-color);
      }
    }
  }
}

/*             sheet-filter-wrapper style                  */

/*             sheet-type-tabs style                  */

.sheet-type-tabs {
  // @apply h-[calc(100vh-116px)] w-full;
  @apply h-full w-full;
  .sheet-tabs {
    @apply min-h-[40px] relative;
    .MuiTabs-flexContainer {
      .MuiTab-root.Mui-selected {
        @apply bg-base-color-light text-white;
        border: none;
      }
      .MuiTab-root {
        @apply min-h-[40px] h-[40px] normal-case text-black;
        border: 1px solid rgba(var(--base-color-separators), 1);
        &:not(:first-child) {
          @apply ml-2;
        }
      }
    }
    .MuiTabs-indicator {
      @apply hidden;
    }
  }

  &.is-mini {
    width: calc(100% - 300px);
    transition: width 0.3s;
  }

  &.is-mini-dual {
    width: calc(100% - 610px);
    transition: width 0.3s;
  }

  .sheet-top-action-wrapper {
    @apply flex items-center justify-between w-full ml-4 flex-wrap;

    .sheet-status-items {
      @apply flex my-1;
      .sheet-status-item {
        @apply flex items-center cursor-pointer px-2;
        &:not(:first-of-type) {
          @apply ml-2;
        }

        .status-item-indicator {
          @apply w-[14px] h-[14px] rounded-sm mr-1;
        }

        .status-item-text {
          @apply text-gray-700 text-[0.8rem] leading-3;

          .found-homes-number {
            @apply flex items-center py-1.5 px-1.5 ml-1 rounded bg-base-color-light-f-15 text-[0.8rem] text-base-color;
          }
        }

        &.is-disabled {
          @apply opacity-40;
        }
      }
    }
  }

  .sheet-shaxmatka-type-wrapper {
    .shaxmatka-type-btn {
      .btn-icon {
        @apply text-sm leading-3 mt-0 -mr-2 ml-0.5 origin-center transition-transform;
      }
    }
  }
}

/*             sheet-type-tabs style                  */

/*             sheet-actions-area style                  */

/* .sheet-actions-area {
	@apply translate-x-[300px] relative transition-transform duration-300;
	&.is-full {
		@apply translate-x-0 w-[290px] my-shadow-2 rounded-lg p-4 pt-8 overflow-y-auto h-[95vh] ml-4 sticky top-4;
	}
	.sheet-form-action {
	}
	.sheet-global-actions {
		@apply mt-8 border-t-2 border-t-base-color-light pt-8;
	}
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--base-color-light);
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--base-color);
	}

	&::-webkit-scrollbar-track {
		border-radius: 4px;
		background: var(--white-color);
	}
} */

.sheet-actions-area {
  @apply translate-x-[290px] relative w-0 opacity-0 transition-[transform,opacity] duration-300;
  &.is-full {
    @apply translate-x-0 w-[290px] min-h-[calc(100vh-120px)] opacity-100 ml-4;

    .sheet-actions-body {
      @apply w-full h-[calc(100vh-80px)] my-shadow-2 rounded-lg p-4 top-4 overflow-y-auto;
      .sheet-form-action {
      }

      .price-form-item-wrapper {
        @apply -mx-1 flex items-center flex-wrap;
        .field-item {
          @apply h-8 min-w-[32px] min-h-[32px] max-w-max px-3 m-[5px] text-sm leading-3;
          .item-selected {
            @apply bg-base-color-light;
          }
        }
      }

      .price-form-item-divider {
        @apply w-full h-[1px] bg-gray-300 my-2;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--base-color-light);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--base-color);
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: var(--white-color);
      }
    }
  }
}

/*             sheet-actions-area style                  */
/*            sheet-actions-home-detail-wrapper style                  */

.sheet-actions-home-detail-wrapper {
  @apply mt-4;
  .home-detail-header {
    .home-detail-title {
      @apply text-lg text-center leading-5 font-medium;
    }
  }
  .home-detail-body {
    @apply mt-6;
    .home-detail {
      @apply flex items-center justify-between text-sm my-2;
      .home-detail-item {
      }
      .home-detail-item-value {
      }

      &.home-image-wrapper {
        @apply mb-3 min-h-[150px] italic justify-center rounded-lg my-shadow-1 relative overflow-hidden;
        img {
          @apply rounded-lg;
        }
        .home-image-view-btn {
          @apply transition-opacity duration-300 opacity-0 absolute bottom-0 right-0;
        }
        &:hover .home-image-view-btn {
          @apply transition-opacity duration-300 opacity-100;
        }
      }
    }
  }
}

/*            sheet-actions-home-detail-wrapper style                  */

/*             sheet-table-wrapper style                  */

.sheet-table-wrapper {
  @apply flex-auto h-[calc(100vh-140px)] relative;
  .sheet-table {
    @apply overflow-y-auto;
    .sheet-table-head {
      .sheet-table-head-cell {
      }
    }
    .sheet-table-body {
      @apply overflow-hidden;
      .sheet-table-row {
        @apply transition-[opacity] cursor-pointer;
        &.home-item.is-disabled {
          @apply transition-[opacity] opacity-40 relative;
          .sheet-table-body-cell {
            @apply text-[0.775rem] transition-[font-size] leading-3;
            .MuiChip-root {
              @apply scale-95;
            }
          }
        }

        &.home-item.item-selected {
          @apply bg-base-color-active-deprecated-d-02;
        }

        .sheet-table-body-cell {
          @apply transition-[font-size] relative;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--white-color);
  }
}

/*             sheet-table-wrapper style                  */

/*             shaxmatka-select-mode style                  */

.shaxmatka-select-mode {
  .adornment-icon {
    @apply flex items-center justify-center w-6 h-6 text-base-color-light leading-4 border border-base-color-light p-1 rounded-[6px] cursor-default;
    &.view-icon {
      @apply text-base;
    }
    &.edit-icon {
      @apply text-sm;
    }
    &.is-view-only {
      @apply text-gray-400 leading-4 border border-gray-400 p-1 rounded-lg cursor-default;
    }
  }
  &.Mui-disabled {
    .adornment-icon {
      @apply text-gray-400 border-gray-400;
    }
  }
}

/*             shaxmatka-select-mode style                  */

/*             shaxmatka-cards-list-wrapper style                  */

.shaxmatka-cards-list-wrapper {
  @apply w-full h-full flex flex-col;
  .shaxmatka-card-items {
    @apply pb-3 pt-2;
    .shaxmatka-card-item {
      .shaxmatka-card {
        @apply h-full bg-white my-shadow-2 transition-all duration-200 text-black flex justify-center rounded-lg;
        .shaxmatka-card-content-wrapper {
          @apply flex flex-col w-full;
          .shaxmatka-card-image {
            @apply w-full h-[200px] object-cover rounded-t-lg;
          }
          .shaxmatka-card-content-body {
            @apply p-5 flex flex-col h-full justify-end;
            .shaxmatka-card-title {
              @apply text-2xl font-medium;
            }
            .shaxmatka-card-details {
              @apply mt-3;
              .card-detail-item {
                @apply flex items-center my-0.5;
                .card-detail-item-icon {
                  @apply text-base-color text-xl w-[25px] mr-1;
                }
                .card-details-item-value span {
                  @apply font-medium;
                }
              }
            }
          }
        }
        &:hover {
          @apply my-shadow-4 -translate-y-0.5;
        }
      }
    }
  }
}

/*             shaxmatka-cards-list-wrapper style                  */

/*             shaxmatka-visual-view-wrapper style                  */

.shaxmatka-visual-view-wrapper {
  @apply h-[calc(100vh-124px)] w-full overflow-hidden relative rounded-md;

  .visual-view-header {
    @apply z-20 absolute top-3 left-3 flex items-center;
    .visual-view-back-btn-wrapper {
      @apply bg-base-1 w-8 h-8 rounded-lg mr-2;
    }
    .visual-view-title {
      @apply py-1 px-2 bg-base-2 rounded-md text-base font-medium text-base-color;
    }
  }

  .visual-view-body {
    @apply absolute w-full h-full flex justify-center;

    .helper-image {
      @apply absolute w-full h-full object-cover brightness-[0.7] blur-lg;
    }
    .main-image {
      @apply object-contain w-auto h-auto z-10;
    }
    .svg-item {
      @apply absolute z-[12] w-full h-full;
    }
    .home-svg-polygons {
      .home-polygon {
        @apply transition-all cursor-pointer;

        &.status-1 {
          @apply stroke-[3] stroke-base-color-light fill-base-color-light;
          &:hover {
            @apply stroke-base-color-hover fill-base-color-active;
          }
        }
        &.status-2 {
          @apply stroke-[3] stroke-orange-500 fill-orange-500;
          &:hover {
            @apply stroke-orange-600 fill-orange-800;
          }
        }
        &.status-3 {
          @apply stroke-[3] stroke-red-600 fill-red-600;
          &:hover {
            @apply stroke-red-700 fill-red-800;
          }
        }
        &.status-4 {
          @apply stroke-[3] stroke-gray-700 fill-gray-700;
          &:hover {
            @apply stroke-gray-800 fill-gray-900;
          }
        }
      }
    }
    .stage-svg-polygons {
      .stage-polygon {
        @apply stroke-transparent fill-transparent stroke-0 transition-all cursor-pointer;

        &:hover {
          @apply stroke-2 stroke-red-500 fill-red-500;
        }
      }
    }
    .block-svg-polygons {
      .block-polygon {
        @apply stroke-transparent fill-transparent stroke-0 transition-all cursor-pointer;

        &:hover {
          @apply stroke-[5] stroke-red-500 fill-red-500;
        }
      }
    }
  }

  .visual-view-home-numbers-wrapper {
    @apply w-full h-full z-50 relative pointer-events-none;
    .home-number-item {
      @apply absolute px-2 py-1 rounded-md flex flex-col items-center leading-3 transition-transform;
      transform: translate(var(--xAxis), var(--yAxis));
      .home-number {
        @apply text-[12px] font-medium;
      }
      .home-status-name {
        @apply text-[12.5px] font-semibold;
      }
      &.status-1 {
        @apply bg-green-100 text-green-600 border-2 border-solid border-green-600;
        // @apply bg-base-2 text-base-color-light border-2 border-solid border-base-color-light;
      }
      &.status-2 {
        @apply bg-orange-100 text-orange-400 border-2 border-solid border-orange-400;
      }
      &.status-3 {
        @apply bg-red-100 text-red-500 border-2 border-solid border-red-500;
      }
      &.status-4 {
        @apply bg-gray-100 text-gray-500 border-2 border-solid border-gray-500;
      }

      &.is-hovered {
        transform: translate(var(--xAxis), var(--yAxis)) scale(1.2);
      }
    }
  }
}

/* .block-svg-polygons {
	.block-polygon {
		// z-index: 11;
		// @apply stroke-red-600 fill-red-500 stroke-[5] transition-all;
		// clip-path: var(--i);
		@apply stroke-transparent fill-transparent stroke-0 transition-all;
		// // clip-path: circle(10%);
		// &::before {
		// 	content: "";
		// 	// position: absolute;
		// 	// top: 0;
		// 	// right: 0;
		// 	// bottom: 0;
		// 	// left: 0;
		// 	// fill: red;
		// 	// clip-path: circle(10%);
		// }

		&:hover {
			@apply stroke-[5] stroke-red-800 fill-red-500;
			// clip-path: circle(100%);
		}
		// &:hover {
		// 	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.6);
		// }
	}
	// .block-polygon.with-clip-path {
	// 	clip-path: circle(0);
	// 	fill: red;
	// 	transition: all 0.5s linear;
	// }
	// .block-polygon:hover + .block-polygon.with-clip-path,
	// .block-polygon.with-clip-path:hover {
	// 	// @apply fill-red-500 z-10;
	// 	clip-path: circle(100%);
	// }
} */

.skeleton-mask {
  animation: mask 5s ease var(--delay-time) infinite;
  // transform: rotate(45deg);
  // transform-origin: center center;
}
@keyframes mask {
  from {
    // transform: translateX(0);
    transform: translateX(0) translateY(0) rotate(45deg);
    // transform: translateX(0) translateY(1500px) rotate(-45deg);
    transform-origin: center center;
  }
  to {
    // transform: translateX(1500px);
    transform: translateX(1500px) translateY(1500px) rotate(45deg);
    // transform: translateX(1500px) translateY(0) rotate(-45deg);
    transform-origin: center center;
  }
}

/*             shaxmatka-visual-view-wrapper style                  */

/*             shaxmatka-visual-view-homes-wrapper style                  */

.shaxmatka-visual-view-homes-wrapper {
  @apply flex flex-row w-full;
  .stages-swiper-wrapper {
    @apply w-[100px] px-2 h-[calc(100vh-124px)] flex items-center justify-center;
    .stages-swiper-body {
      @apply h-[calc(100vh-140px)] w-full relative;
      .swiper-stage-item {
        @apply px-2 m-0.5 w-full h-full;
        .stage-btn {
          @apply my-shadow-1 text-xl text-base-color rounded-lg w-full h-full;
          &.is-selected {
            @apply bg-base-color text-white;
          }
        }
      }

      .swiper-navigation-button-wrapper {
        @apply absolute left-[30px] z-10;
        &.prev {
          @apply -top-1.5 rotate-90;
        }
        &.next {
          @apply -bottom-1.5 rotate-90;
        }
        &.is-disabled {
          @apply opacity-50;
        }
      }
    }
  }
  .homes-view-wrapper {
    @apply w-[calc(100%-100px)] relative;
  }
}

/*             shaxmatka-visual-view-homes-wrapper style                  */

/*             shaxmatka-block-tooltip-wrapper style                  */

.shaxmatka-block-tooltip-wrapper {
  @apply bg-white text-base-color rounded-lg p-0 #{!important};
  .shaxmatka-block-tooltip-details {
    @apply flex flex-col p-3 rounded-lg my-shadow-1;
    .block-name {
      @apply text-2xl;
    }
    .block-total-homes-number {
      @apply my-4 text-xl border-t-2 border-solid border-gray-400 pt-3;
    }
    .block-home-statuses {
      @apply w-[200px] relative;
      .home-status-item {
        @apply flex items-center justify-between py-1 px-2 rounded-md bg-gray-200 text-black text-base mb-2;

        .home-count {
          @apply font-medium;
        }

        // &.status-1 {
        // 	@apply bg-green-100 text-green-600 border-2 border-solid border-green-600;
        // }

        // &.status-2 {
        // 	@apply bg-orange-100 text-orange-400 border-2 border-solid border-orange-400;
        // }

        // &.status-3 {
        // 	@apply bg-red-100 text-red-500 border-2 border-solid border-red-500;
        // }

        &:last-child {
          @apply mb-0;
        }
      }
    }
  }
}

/*             shaxmatka-block-tooltip-wrapper style                  */

/*             home-detail-to-print-wrapper style                  */

.home-detail-to-print-wrapper {
  @apply mx-2 flex flex-col min-h-screen;
  .row-data {
    @apply flex flex-row items-center justify-between;
  }

  .row-data-item {
    @apply flex flex-col justify-center;
    .is-start {
      @apply items-start;
    }
    .is-center {
      @apply items-center text-center;
    }
    .is-end {
      @apply items-end;
    }

    .helper-text {
      @apply text-sm text-gray-500;
    }

    .home-rooms {
      @apply w-[35px] h-[35px] text-sm relative transition-[scale,opacity] p-2 my-shadow-1 rounded-lg flex flex-col tracking-[-0.3px] bg-base-color-light-f-15;
    }
  }

  .divider {
    @apply border-t-[1px] border-gray-300 my-4;
  }
}

/*             home-detail-to-print-wrapper style                  */

/*             home-filter-rooms-number style                  */

.home-filter-rooms-number {
  @apply h-8 min-w-[32px] max-w-min relative;
  padding-right: 4px !important;
  padding-left: 4px !important;
  border: 1px solid rgba(var(--base-color-separators), 1) !important;
  &:hover {
    @apply bg-base-color-deprecated-bg;
  }
  &.item-selected {
    @apply bg-base-color my-shadow-1 text-white;
  }
}

/*             home-filter-rooms-number style                  */

/*             step-icon-wrapper style                  */

.step-icon-wrapper {
  .step-icon {
    @apply flex items-center justify-center bg-base-color text-white w-[40px] h-[40px] rounded-full;
    i {
      @apply text-2xl;
    }
  }
}

.MuiStepLabel-iconContainer.Mui-disabled {
  .step-icon {
    @apply bg-base-3;
  }
}

/*             step-icon-wrapper style                  */

/*             clients-database-wrapper style                  */

.clients-database-wrapper {
  .clients-database-title {
  }
  .clients-database-add-edit {
  }
  .clients-database-body {
    .client-item {
      &.item-selected,
      &:hover {
        @apply bg-base-color text-white;
      }
      // @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-2 border-solid border-base-color my-2 #{!important};
      @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-solid border-base-color border-2 my-2;
      // TODO(can't build when used #{!important} in this row) @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-solid border-base-color border-2 my-2 #{!important};
    }
  }
}

/*             clients-database-wrapper style                  */

/*             client-homes-database-wrapper style                  */

.client-homes-database-wrapper {
  .client-homes-database-title {
  }
  .client-homes-database-add-edit {
  }
  .client-homes-database-body {
    .home-database-item {
      &.item-selected,
      &:hover {
        @apply bg-base-color text-white;
      }
      // @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-2 border-solid border-base-color my-2 #{!important};
      @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-solid border-base-color border-2 my-2;
      // TODO(can't build when used #{!important} in this row) @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-solid border-base-color border-2 my-2 #{!important};
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--base-color-light);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--base-color);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: var(--white-color);
    }
  }
}

/*             client-homes-database-wrapper style                  */

/*             contracts-database-wrapper style                  */

.contracts-database-wrapper {
  .contracts-database-body {
    @apply overflow-y-auto max-h-[250px] pr-2;
    .contract-item {
      @apply relative;

      &.item-selected,
      &:hover {
        @apply bg-base-color text-white;
      }
      // @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-2 border-solid border-base-color my-2 #{!important};
      @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-solid border-base-color border-2 my-2;
      // TODO(can't build when used #{!important} in this row) @apply p-2 rounded-lg flex-col items-start my-shadow-2 cursor-pointer transition-colors border-solid border-base-color border-2 my-2 #{!important};

      .currency-sign {
        span {
          @apply absolute top-2 right-2 text-[12px] leading-4 p-1 rounded-lg text-white;
          &.currency-dollar {
            @apply bg-green-700;
          }
          &.currency-sum {
            @apply bg-base-color-light;
          }
        }
      }

      & span {
        @apply text-justify;
      }

      .contract-item-detail {
        @apply flex flex-row w-full text-sm items-center justify-between;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--base-color-light);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--base-color);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: var(--base-color-light-hover);
    }
  }
}

/*             contracts-database-wrapper style                  */

/*             contract-details-wrapper style                  */

.contract-details-wrapper {
  .contract-details-body {
    .contract-detail-item {
      @apply flex flex-row w-full items-center justify-between my-1;
      & span {
        @apply text-justify;
        &:last-child {
          @apply ml-4;
        }
      }
      & span.currency-format {
        @apply ml-0;
      }
    }
  }
}

/*             contract-details-wrapper style                  */

/*             client-home-details style                  */

.client-home-details {
  @apply mt-2;
  .client-home-detail-items {
    @apply p-4 my-shadow-2 rounded-lg mt-4;
    .home-item {
      @apply text-base flex items-center my-1;
      .home-item-title {
        @apply font-medium text-base-color-active;
      }
      .home-item-value {
        @apply ml-2;
      }
    }
  }
}

/*             client-home-details style                  */

/*             home-repair-type style                  */

.home-repair-type {
  .title {
    @apply text-xl text-base-color text-center;
  }

  .repair-types {
    @apply mx-20 flex flex-row mt-4;
    .repair-type {
      // @apply w-1/2 my-shadow-2 p-4 rounded-lg flex-col text-base transition-colors border-2 border-solid border-base-color #{!important};
      @apply w-1/2 my-shadow-2 p-3 rounded-lg flex-col text-base leading-5 transition-colors border-2 border-solid border-base-color;
      .price-by-currency-wrapper {
        @apply flex justify-end text-gray-500 text-[14px] leading-4 w-full;
      }
      &.item-selected,
      &:hover {
        @apply bg-base-color text-white;
        .price-by-currency-wrapper {
          @apply text-gray-400;
        }
      }
    }
  }
}

/*             home-repair-type style                  */

/*             stepper-items-wrapper style                  */

.stepper-items-wrapper {
  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--base-color-light-hover);
  }
}

/*             stepper-items-wrapper style                  */

/*             step-three-payment-tables-wrapper style                  */

.step-three-payment-tables-wrapper {
  @apply flex justify-center mt-6;
  .contract-step-three-table-tabs {
    @apply transition-[width] relative;
    .MuiTabs-flexContainer {
      .MuiTab-root.Mui-selected {
        @apply my-shadow-2 rounded-lg bg-base-color text-white border-none;
      }
      .MuiTab-root {
        @apply normal-case text-black rounded-none;
        border-bottom: 2px solid rgba(var(--base-color-separators), 1);
        &:not(:first-child) {
          @apply ml-2;
        }
      }
    }
    .MuiTabs-indicator {
      @apply hidden;
    }
  }
}

/*             step-three-payment-tables-wrapper style                  */

/*             not-found-page style                  */

.not-found-page {
  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--base-color-light-hover);
  }
}

/*             not-found-page style                  */

/*             table-column-drawer-wrapper style                  */

.table-column-drawer-wrapper {
  @apply w-[400px] flex flex-col p-6 overflow-auto;
  .table-column-drawer-item {
    @apply mx-0 justify-end #{!important};
    .MuiTypography-root {
      @apply text-base;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--base-color-light-hover);
  }
}

/*             table-column-drawer-wrapper style                  */

/*             order-home-modal-wrapper style                  */

.order-home-modal-wrapper {
  .home-client-wrapper {
  }

  .home-data-wrapper {
    .home-data-item {
      @apply text-base flex items-center my-1;
      .data-item-title {
        @apply font-medium text-base-color-active;
      }
      .data-item-value {
        @apply ml-2;
      }
    }
  }

  .client-wrapper {
    @apply min-w-[200px] w-full p-4 my-shadow-1 rounded-lg flex flex-col items-start bg-white mt-4;
    &:hover {
      @apply bg-base-color-deprecated-bg;
    }
    &.item-selected {
      @apply bg-base-color my-shadow-1 text-white;
    }
  }
}

/*             order-home-modal-wrapper style                  */

/*             order-action-modal-wrapper, order-view-modal-wrapper style                  */

.order-action-modal-wrapper,
.order-view-modal-wrapper {
  .home-data-wrapper {
    .home-data-item {
      @apply text-base flex items-center my-1 w-full;
      .data-item-title {
        @apply font-medium text-base-color-active;
      }
      .data-item-value {
        @apply ml-2;
      }
    }
  }
}

/*             order-action-modal-wrapper, order-view-modal-wrapper style                  */

/*             order-view-wrapper style                  */

.order-view-wrapper {
  .home-data {
    @apply flex items-center justify-between my-shadow-2 rounded-lg p-4 w-full mb-4;
    .home-data-item {
      @apply flex flex-col;
      .data-item-title {
        @apply text-base font-medium text-base-color;
      }
      .data-item-value {
        @apply text-base font-medium;
      }
    }
  }
}

/*             order-view-wrapper style                  */

/*             client-view-modal-wrapper style                  */

.client-view-modal-wrapper {
  .client-data-wrapper {
    .client-data-item {
      @apply text-base flex items-center my-1 w-full;
      .data-item-title {
        @apply font-medium text-base-color-active;
      }
      .data-item-value {
        @apply ml-2;
      }
    }
  }
}

/*             client-view-modal-wrapper style                  */

/*             client-view-wrapper style                  */

.client-view-wrapper {
  @apply my-shadow-2 p-6 rounded-lg mt-4 flex;
  .client-data-wrapper {
    .client-data-item {
      @apply text-base flex items-center my-1 w-full;
      .data-item-title {
        @apply font-medium text-base-color-active;
      }
      .data-item-value {
        @apply ml-2;
      }
    }
  }
}

/*             client-view-wrapper style                  */

/*             client-contracts-tabs style                  */

.client-contracts-tabs {
  @apply transition-[width] w-full;
  @apply relative;
  .MuiTabs-flexContainer {
    .MuiTab-root.Mui-selected {
      @apply my-shadow-2 rounded-lg bg-base-color text-white border-none;
    }
    .MuiTab-root {
      @apply normal-case text-black rounded-none;
      border-bottom: 2px solid rgba(var(--base-color-separators), 1);
      &:not(:first-child) {
        @apply ml-2;
      }
    }
  }
  .MuiTabs-indicator {
    @apply hidden;
  }
}

/*             client-contracts-tabs style                  */

/*             leads-tabs style                  */

.leads-tabs {
  @apply transition-[width] w-full;
  @apply relative;
  .MuiTabs-flexContainer {
    .MuiTab-root.Mui-selected {
      @apply my-shadow-2 rounded-lg bg-base-color text-white border-none;
    }
    .MuiTab-root {
      @apply text-black rounded-none w-1/3 max-w-[calc(100%/3)] normal-case;
      border-bottom: 2px solid rgba(var(--base-color-separators), 1);
      &:not(:first-child) {
        @apply ml-2;
      }
    }
  }
  .MuiTabs-indicator {
    @apply hidden;
  }
}

/*             leads-tabs style                  */

/*             lead-view-wrapper style                  */

.lead-view-wrapper {
  @apply mx-4;
  .lead-details-wrapper {
    @apply my-shadow-2 p-6 rounded-lg mt-4 flex;
    .lead-data-wrapper {
      @apply md:w-1/2 w-full;
      .lead-data-item {
        @apply text-base flex items-center my-1 w-full;
        .data-item-title {
          @apply font-medium text-base-color-active;
        }
        .data-item-value {
          @apply ml-2;
        }
      }
    }
  }

  .lead-homes-comments-list {
    @apply mt-4 mb-6 flex;
    .lead-homes-wrapper {
      @apply md:w-1/2 w-full pr-3;
      .homes-list-title {
        @apply my-2 text-lg font-medium text-base-color;
      }
      .base-table {
        @apply w-full h-full flex flex-col;
      }
    }
    .lead-comments-wrapper {
      @apply md:w-1/2 w-full pl-3;
      .comments-list-title {
        @apply my-2 text-lg font-medium text-base-color;
      }
      .lead-comments-list {
        @apply min-h-[100px] max-h-[800px] overflow-y-auto my-shadow-2 p-3 rounded-lg;
        .comment-item-wrapper {
          @apply text-[14px] transition-[background] mb-1 flex flex-row p-2 rounded-lg;
          .comment-item-body {
            @apply ml-1 w-full;
            .comment-item-staff-date {
              @apply text-end text-[12px] leading-4;
              .comment-item-staff {
                @apply italic text-gray-800;
              }
              .comment-item-date {
                @apply ml-2 text-gray-500;
              }
            }
          }
          &:hover {
            @apply bg-base-color-light-hover;
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--base-color-light);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: var(--base-color);
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background: var(--white-color);
        }
      }
    }
  }
}

/*             lead-view-wrapper style                  */

/*             changes-table style                  */

.changes-table {
  .MuiTableBody-root {
    .MuiTableRow-root {
      @apply relative;
      &:hover {
        background-color: rgba(var(--base-color-light-separators), 0.15);
        &::after {
          content: '';
          @apply absolute left-0 w-[4px] h-full bg-base-color;
        }
      }
    }
  }
}

/*             changes-table style                  */

/*             changes-tab-three-payment-tables-wrapper style                  */

.changes-tab-three-payment-tables-wrapper {
  @apply flex justify-center mt-6;
  .contract-step-three-table-tabs {
    @apply transition-[width] relative;
    .MuiTabs-flexContainer {
      .MuiTab-root.Mui-selected {
        @apply my-shadow-2 rounded-lg bg-base-color text-white border-none;
      }
      .MuiTab-root {
        @apply normal-case text-black rounded-none;
        border-bottom: 2px solid rgba(var(--base-color-separators), 1);
        &:not(:first-child) {
          @apply ml-2;
        }
      }
    }
    .MuiTabs-indicator {
      @apply hidden;
    }
  }
}

/*             changes-tab-three-payment-tables-wrapper style                  */

/*             formik-editor-wrapper style                  */

.formik-editor-wrapper {
  @apply border hover:border-opacity-100 z-[95] min-h-[300px] rounded-lg;
  .formik-editor-toolbar {
    .w-e-toolbar {
      @apply z-[93] rounded-t-lg;
    }
  }
  .formik-editor {
    @apply h-[300px] border-t border-black border-opacity-20;
    .w-e-text-container {
      @apply z-[92] rounded-b-lg h-full;
    }
  }
}

/*             formik-editor-wrapper style                  */

/*             news-wrapper style                  */

.news-wrapper {
  @apply flex flex-row px-8;
  .news-item-details-wrapper {
    @apply w-2/3 pr-10;
    .selected-news-item-video {
      @apply rounded-lg max-h-[400px] overflow-hidden;
    }
    .selected-news-title {
      @apply text-xl font-medium w-full;
    }
    .selected-news-body {
      @apply text-justify;
      a {
        @apply underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .news-list-wrapper {
    @apply h-[95vh] my-shadow-2 rounded-lg p-4 sticky top-4 overflow-y-auto w-1/3;
    .news-item {
      &.item-selected,
      &:hover {
        @apply bg-base-color text-white;
      }
      @apply w-full p-2 rounded-lg flex-col items-start my-shadow-2 transition-colors border-solid border-base-color border-2 my-2 cursor-pointer;

      & span {
        @apply text-justify;
      }
      .news-item-video {
        @apply w-1/2 rounded-lg h-[inherit] overflow-hidden;
      }
      .news-content {
        @apply text-left w-1/2 pl-4 py-1;
        .news-title {
          @apply text-line-2 text-lg leading-5 font-medium;
        }
        .news-body {
          @apply text-line-[4] leading-5;
          a {
            background-image: none;
            @apply select-none pointer-events-none;
          }
          p,
          span,
          div {
            color: var(--white-color) !important;
            background-color: transparent !important;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--base-color-light);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--base-color);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: var(--white-color);
    }
  }
}

/*             news-wrapper style                  */

/*             setting-wrapper style                  */

.setting-wrapper {
  .settings-item {
    @apply no-bg-underline flex items-center justify-center h-24;
    .settings-item-btn {
      @apply my-shadow-1 h-full py-4 text-xl;
    }
  }
}

/*             setting-wrapper style                  */

/*             setting-sms-wrapper style                  */

.setting-sms-wrapper {
  .settings-sms-template {
    .remove-key-element {
      @apply hidden;
    }
    &.editing {
      .remove-key-element {
        @apply inline;
      }
    }
  }
}

/*             setting-sms-wrapper style                  */

/*             boss-page-wrapper style                  */

.boss-page-wrapper {
  @apply flex flex-col h-full;
  .boss-page-item-wrapper {
    @apply px-4 py-2 rounded-lg my-shadow-2 relative h-full flex flex-col;
    .boss-page-item-title {
      @apply my-2 text-lg leading-5 font-medium text-base-color;
    }

    .boss-page-item-body {
      @apply h-full;
      .circular-progress-box {
        @apply py-5 h-full;
      }
      .no-data-found-wrapper {
        @apply h-full;
      }
    }

    .boss-page-item-refresh-btn {
      @apply top-2 right-[5px] absolute;
    }
  }
}

/*             boss-page-wrapper style                  */

/*             permission-wrapper style                  */

.permission-wrapper {
  @apply h-[calc(100vh-130px)] mt-1 pb-2 mx-4;
  .MuiTableBody-root {
    .MuiTableRow-root {
      &.is-parent-permission {
        @apply bg-base-color-light-f-15;
        &:hover {
          @apply bg-base-2;
        }
      }
    }
  }
}

/*             permission-wrapper style                  */

/*             crm-wrapper style                  */

.crm-wrapper {
  @apply rounded-lg p-2 h-full w-full overflow-hidden flex;

  .crm-block-columns-wrapper {
    @apply w-full h-full flex flex-row;
    .crm-block-columns-grid {
      @apply flex-nowrap h-full m-0 w-full overflow-y-auto;
      .crm-block-grid-column {
        @apply lg:min-w-[calc(100%/6)] md:min-w-[25%] sm:min-w-[calc(100%/3)] min-w-[50%] w-1/6 h-full p-0 mx-2 rounded-lg flex flex-col justify-center;

        .crm-block-grid-column-title-wrapper {
          @apply flex flex-col;
          .drag-btn {
            @apply text-center text-base-color flex items-center justify-center h-0 opacity-0 transition-[height,opacity] rounded;
            &:active {
              @apply bg-base-color-light-f-15 h-4 opacity-100;
            }
          }
          .crm-block-grid-column-title {
            @apply text-lg leading-5 bg-white rounded-lg px-2 py-3 relative overflow-hidden;
            &::before {
              content: '';
              @apply absolute top-0 left-0 w-full h-1;
              background-color: var(--card-color, var(--base-color-light));
            }
            color: var(--card-color, var(--base-color));
          }

          &:hover .drag-btn {
            @apply h-4 opacity-100;
          }
        }

        .crm-block-grid-column-body {
          @apply h-[calc(100%-8px)] overflow-y-auto overflow-x-hidden mt-2;
          // @apply h-[calc(100%-50px)] overflow-y-auto overflow-x-hidden mt-2;
          scrollbar-gutter: auto;

          .column-cards-wrapper {
            @apply h-full;
            .column-card {
              @apply bg-white rounded-lg my-2 p-2 text-sm block h-[62px] overflow-hidden;
              &:active {
                @apply my-shadow-1;
              }

              &:first-child {
                @apply mt-0;
              }

              &:last-child {
                @apply mb-0;
              }
            }

            &.card-dragging-over-column {
              @apply w-full h-full bg-base-color-light-f-15 rounded-lg transition-[background-color,box-shadow] shadow-base-color-light shadow-inner;
            }
          }

          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }

          &:hover,
          &:focus,
          &:active {
            // @apply pr-0.5;
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--base-color-light-separators), 0.5);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: rgba(var(--base-color-light-separators), 0.9);
          }

          &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: var(--base-color-light-hover);
          }
        }

        &:first-child {
          @apply ml-0;
        }

        &:last-child {
          @apply mr-0;
        }

        // &.is-dragging {
        // 	@apply opacity-80;
        // }
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
  }
}

/*             crm-wrapper style                  */

/*             crm-top-panel-wrapper style                  */

.crm-top-panel-wrapper {
  @apply flex w-full items-center pr-3 mr-3 border-r-2 border-gray-200;
  .crm-blocks-wrapper {
    .crm-block-btn {
      .btn-icon {
        @apply text-sm leading-3 mt-0.5 -mr-2 ml-0.5 origin-center transition-transform;
      }
    }
  }
}

/*             crm-top-panel-wrapper style                  */

/*             crm-card-add-edit-wrapper style                  */

.crm-card-add-edit-wrapper {
  @apply h-full;
  .crm-card-add-edit-body {
    @apply h-full;
    .crm-card-add-edit-body-wrapper {
      @apply flex flex-row h-full;
      .add-edit-body-wrapper {
        @apply p-3 h-full overflow-y-auto;

        .item-wrapper {
          @apply flex flex-row items-center h-10;
          .item-label {
            @apply w-2/5 text-gray-600 text-sm pr-1;

            &.contact-label {
              @apply w-[35%] relative;
            }
          }
          .item-value {
            @apply w-3/5;
            .MuiInputBase-root {
              @apply mt-0 h-8;
              .MuiInputBase-input {
                @apply pt-0;
              }
            }

            &.contact-value {
              @apply w-[65%] relative;
            }

            &.phone-value {
              @apply flex items-center;
              .add-phone-btn {
                @apply text-gray-500 rounded-full w-8 h-8 p-2 -ml-2;
                i {
                  @apply grid place-items-center;
                }
              }
            }
          }

          &.contact-details {
            @apply flex flex-row items-center h-12;
            .MuiInputBase-root {
              @apply h-8;
              .MuiInputBase-input {
                @apply pt-0;
              }
            }

            .contact-person-icon {
              @apply text-[40px] text-gray-400 mr-4;
            }
          }

          &.contact-phones {
            @apply h-8;
          }
        }

        .contact-divider {
          @apply border-b-2 border-base-color mt-3 mb-2;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--base-3);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: var(--base-color-light);
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background: var(--white-color);
        }
      }

      .lead-history-body-wrapper {
        @apply w-3/4 ml-3 bg-base-color-light-f-15 rounded-lg;
        .lead-history-items-wrapper {
          @apply w-full h-full relative;

          .lead-history-details-wrapper {
            @apply h-[calc(100%-200px)] relative overflow-y-auto;

            &.is-new {
              @apply h-full relative overflow-y-auto;
            }

            .date-history-wrapper {
              @apply flex items-center flex-col text-sm leading-4 mt-3;
              .date-time-title {
                @apply bg-white rounded-lg p-2 mb-3 cursor-default select-none;
              }
              .date-items-wrapper {
                @apply w-full px-3;
                .item-details-wrapper {
                  @apply bg-white rounded-lg p-2 mb-3;
                }
              }
            }

            .no-data-placeholder {
              @apply w-full h-full flex items-center justify-center;
            }

            .message-actions-wrapper {
              @apply absolute -top-1 -right-2;
              .message-action-menu-btn {
                @apply text-gray-500 rounded-full w-8 h-8 p-2 -ml-2;
                i {
                  @apply grid place-items-center;
                }
              }
            }

            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--base-3);
              border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: var(--base-color-light);
            }

            &::-webkit-scrollbar-track {
              border-radius: 4px;
              background: var(--white-color);
            }
          }

          .lead-history-input-wrapper {
            @apply w-full z-40 bg-[#eedff6] p-3 rounded-b-lg;

            .input-body {
              @apply w-auto h-[180px] rounded-lg bg-white py-2 px-4;
              .input-top-actions-wrapper {
                @apply flex items-center;
                .types-wrapper {
                  .lead-comment-type-btn {
                    .btn-icon {
                      @apply text-[12px] leading-3 mt-0.5 -mr-2 ml-0.5 origin-center transition-transform;
                    }
                  }
                }

                .calendar-wrapper {
                  .calendar-btn {
                    @apply text-base-color h-[30px] w-auto px-2;
                    i {
                      @apply text-xl flex items-center justify-center;
                    }

                    .calendar-btn-date {
                      @apply text-[12px] leading-4;
                    }
                  }
                }

                .staffs-wrapper {
                  .lead-comment-staff-btn {
                    .btn-icon {
                      @apply text-[12px] leading-3 mt-0.5 -mr-2 ml-0.5 origin-center transition-transform;
                    }
                  }
                }

                .task-types-wrapper {
                  .lead-comment-task-type-btn {
                    .btn-icon {
                      @apply text-[12px] leading-3 mt-0.5 -mr-2 ml-0.5 origin-center transition-transform;
                    }
                  }
                }

                .divider {
                  @apply h-[30px] w-[1px] bg-gray-300 mx-2;
                }
              }

              .input-text-area {
                @apply relative;
                .comment-text-area-field {
                  .MuiInputBase-root {
                    @apply p-2;
                    fieldset {
                      @apply border-[1.2px] border-solid border-gray-200;
                    }

                    &.Mui-focused {
                      fieldset {
                        @apply border-[1.5px] border-solid border-gray-400;
                      }
                    }
                  }
                }

                .comment-text-area-field-placeholder {
                  @apply absolute text-sm text-gray-400 w-full left-2 top-4 select-none pointer-events-none;
                }
              }

              .input-action-buttons-wrapper {
                @apply flex items-center justify-end;
              }
            }
          }
        }
      }
    }
  }
}

/*             crm-card-add-edit-wrapper style                  */

/*             crm-card-add-edit-top-wrapper style                  */

.crm-card-add-edit-top-wrapper {
  @apply flex flex-row items-center;
  .MuiFormLabel-root {
    @apply text-xl leading-4;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      @apply opacity-0 transition-opacity duration-500;
    }
  }
  .MuiFormHelperText-root {
    @apply -bottom-[18px] absolute;
  }
  .MuiInputBase-root {
    @apply h-10 text-xl;
    &.Mui-error {
      // @apply -mb-[0.15rem];
    }
  }
}

/*             crm-card-add-edit-top-wrapper style                  */

/*             tasks-wrapper style                  */

.tasks-wrapper {
  @apply rounded-lg p-2 h-full w-full overflow-hidden flex;

  .tasks-columns-wrapper {
    @apply w-full h-full flex flex-row;
    .tasks-columns-grid {
      @apply flex-nowrap h-full m-0 w-full overflow-y-auto;
      .tasks-grid-column {
        @apply lg:min-w-[20%] md:min-w-[25%] sm:min-w-[calc(100%/3)] min-w-[50%] w-1/6 h-full p-0 mx-2 rounded-lg flex flex-col justify-center;

        .tasks-grid-column-title-wrapper {
          @apply flex flex-col;
          .tasks-grid-column-title {
            @apply text-lg leading-5 bg-white rounded-lg px-2 py-3 relative overflow-hidden text-center text-red-500;
            &::before {
              content: '';
              @apply absolute top-0 left-0 w-full h-1 bg-red-600;
            }
          }
        }

        .tasks-grid-column-body {
          @apply h-[calc(100%-50px)] overflow-y-auto mt-2;
          scrollbar-gutter: auto;

          .task-card {
            @apply bg-white rounded-lg my-2 py-2 px-3 text-sm block;
            &:first-child {
              @apply mt-0;
            }

            &:last-child {
              @apply mb-0;
            }
          }

          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }

          &:hover,
          &:focus,
          &:active {
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--base-color-light-separators), 0.5);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: rgba(var(--base-color-light-separators), 0.9);
          }

          &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: var(--base-color-light-hover);
          }
        }

        &:first-child {
          @apply ml-0;
        }

        &:last-child {
          @apply mr-0;
        }
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
  }
}

/*             tasks-wrapper style                  */

/*             image-preview-content style                  */

.image-preview-content {
  @apply min-w-[300px] m-4;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--base-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--white-color);
  }
}

/*             image-preview-content style                  */

/*             currency-price-sub-content style                  */

.currency-price-sub-content {
  @apply flex ml-2 mb-2 -mt-[5px] text-[14px] leading-4 text-gray-500;
}

/*             currency-price-sub-content style                  */

/*             lead-search-popper-wrapper style                  */

.lead-search-popper-wrapper {
  @apply bg-white lg:w-[300px] w-[200px] max-h-[300px] rounded-lg overflow-x-hidden overflow-y-auto my-shadow-1;
  @apply mt-4 ml-5 #{!important};

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &:hover,
  &:focus,
  &:active {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--base-color-light-separators), 0.5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--base-color-light-separators), 0.9);
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: var(--base-color-light-hover);
  }
}

/*             lead-search-popper-wrapper style                  */

/*             boss-dashboard-wrapper style                  */

.boss-dashboard-wrapper {
  @apply p-4;

  .dashboard-actions {
    @apply flex items-center justify-between mb-4;
    .dashboard-owner-greeting {
      @apply whitespace-nowrap;
    }
  }

  .dashboard-debts-wrapper {
    @apply flex items-center mb-6;

    .price-item-wrapper {
      @apply p-3 rounded-lg;
      &.monthly {
        @apply text-orange-500 border-2 border-orange-400 custom-shadow-[4|1] shadow-orange-300;
      }
      &.total {
        @apply text-red-500 border-2 border-red-400 custom-shadow-[4|1] shadow-red-400;
      }

      .price-item-title {
        @apply text-base;
      }
      .price-item-sum {
        @apply text-xl;
      }
    }
  }

  .dashboard-payments {
    @apply flex flex-col mb-6 p-2.5 my-shadow-1 rounded-lg;
    .payments-title {
      @apply mx-auto text-lg font-medium mb-2;
    }
    .payments-daily-status {
      @apply flex items-center text-start p-3 text-xl;
      .payments-daily-status-sum {
        @apply text-[27px] text-base-color-light ml-2;
      }
    }
  }

  .dashboard-homes {
    .homes-item-wrapper {
      @apply h-28 w-full p-3 flex items-center justify-center flex-col custom-shadow-[4|1] rounded-lg;

      &.free-homes {
        @apply text-green-700 border-2 border-green-700 shadow-green-700;
      }
      &.sold-homes {
        @apply text-red-700 border-2 border-red-700 shadow-red-700;
      }
      &.ordered-homes {
        @apply text-orange-500 border-2 border-orange-500 shadow-orange-500;
      }

      .homes-item-number {
        @apply text-5xl font-medium;
      }
    }
  }
}

/*             boss-dashboard-wrapper style                  */

/*             boss-payment-wrapper style                  */

.boss-payment-wrapper {
  @apply p-4;
  .boss-payment-tab-wrapper {
    @apply p-3;

    &.tab-one {
      .payment-tab-top-actions-wrapper {
        @apply flex items-center justify-end mb-3;
        .payment-tab-top-actions-body {
          @apply flex items-center lg:w-1/2 md:w-2/3 sm:w-3/4 w-full;
        }
      }

      .payment-tab-chart {
        @apply my-shadow-1 rounded-lg p-6 mb-6;
        .chart-header {
          @apply flex items-center justify-between mb-3;
          .chart-title {
            @apply text-center text-xl capitalize leading-6 font-medium flex items-center flex-col;
            .chart-total-sum {
              @apply text-base leading-6 font-normal text-gray-600;
            }
          }
        }
      }

      .payment-tab-table {
      }
    }

    &.tab-two {
      @apply mt-1;
      .item-wrapper {
        @apply my-shadow-1 h-full rounded-lg p-3;
        .item-header {
          @apply flex items-center flex-wrap;
          .item-title {
            @apply text-xl leading-5 font-medium;
          }
          .item-actions {
            @apply flex items-center ml-auto;
          }
        }

        .item-body {
          .chart-wrapper {
            @apply w-full h-[300px] flex items-center mx-auto my-4;
          }
          .chart-data-items {
            @apply flex flex-col;
            .chart-data-item-wrapper {
              @apply p-3 rounded-md text-base-color-light;
              .chart-data-item-body {
                @apply flex items-center justify-between text-sm leading-3 text-gray-700 w-full;
                .item-title {
                  @apply flex items-center;
                  .item-indicator {
                    @apply w-3 h-3 rounded-sm mr-1;
                    &.indicator-intime {
                      background-color: #0a6a0ab0;
                    }
                    &.indicator-inmonth {
                      background-color: #f0dd0db0;
                    }
                    &.indicator-inthreemonth {
                      background-color: #f0990db0;
                    }
                    &.indicator-inyear {
                      background-color: #990399b0;
                    }
                    &.indicator-moreyear {
                      background-color: #400269b0;
                    }
                    &.indicator-never {
                      background-color: #bf1307a6;
                    }
                  }
                }
              }
              &.item-odd {
                @apply bg-gray-200 bg-opacity-50;
              }
              &:hover {
                @apply outline outline-2 outline-base-color-light;
              }
            }
          }

          .expected-payment-items {
            @apply flex flex-col mt-4;
            .expected-payment-item-wrapper {
              .item-title {
                @apply text-sm text-center text-gray-700;
              }
              .item-details {
                @apply flex flex-col mt-1;
                .total-value {
                  @apply w-full text-[14px] font-medium bg-gray-300 bg-opacity-50 m-1 rounded-md p-1.5 text-center;
                }
                .divided-values {
                  @apply w-full m-1 flex flex-row mb-6;
                  .divided-item {
                    @apply text-center flex flex-col relative;
                    width: var(--indicator-width, 50%);

                    .divided-item-indicator {
                      @apply h-9 rounded-md;
                      // @apply h-9 rounded-md p-1.5;
                    }
                    .divided-item-value {
                      @apply text-[14px] absolute -bottom-6 mt-0.5 font-medium whitespace-nowrap;
                    }

                    &.payments {
                      .divided-item-indicator {
                        @apply bg-base-color-light;
                      }
                      .divided-item-value {
                        @apply text-base-color-light left-0;
                      }
                    }

                    &.expected-payments {
                      .divided-item-indicator {
                        @apply bg-yellow-500 bg-opacity-70;
                      }
                      .divided-item-value {
                        @apply text-yellow-700 right-0;
                      }
                    }

                    &:first-child {
                      @apply mr-1;
                    }
                    &:last-child {
                      @apply ml-1;
                    }
                  }
                }
              }

              &:not(:first-child) {
                @apply mt-4;
              }
            }

            .status-indicators {
              @apply flex items-center justify-center mt-3;
              .status-item {
                @apply mx-2 text-gray-700 text-sm flex items-center;
                .indicator-item {
                  @apply w-3 h-3 rounded-sm mr-1;
                }
                &.status-payments .indicator-item {
                  @apply bg-base-color-light;
                }
                &.status-expected-payments .indicator-item {
                  @apply bg-yellow-500 bg-opacity-70;
                }
              }
            }
          }
        }
      }
    }

    &.tab-three {
      .payment-tab-top-actions-wrapper {
        @apply mb-3;
        .payment-tab-top-actions-body {
          @apply flex items-center justify-between w-full;
        }
      }
      .payment-tab-chart {
        @apply my-shadow-1 rounded-lg p-6 mb-6;
      }
    }
  }
}

/*             boss-payment-wrapper style                  */

/*             boss-residential-complex-wrapper style                  */

.boss-residential-complex-wrapper {
  @apply p-4 pb-6;
  .residential-complex-item {
    @apply my-shadow-1 rounded-lg p-3 h-full;
    .residential-complex-item-header {
      @apply flex items-center justify-between;
      .residential-complex-item-title {
        @apply text-gray-700 leading-4;
      }
    }
  }

  .total-statistics {
    .total-statistics-body {
      @apply flex items-center flex-col mt-4;
      .total-statistics-body-header {
        @apply flex items-center flex-col;
        .total-statistics-body-title {
          @apply text-sm text-base-color-light;
        }
        .total-statistics-total-sum {
          @apply text-xl font-medium text-base-color-light mt-2;
        }
      }
      .total-statistics-chart {
        @apply w-full h-[228px] flex items-center mx-auto;
      }
    }
  }

  .income-by-residential-complex {
    .income-by-residential-body {
      @apply mt-1;
      .income-by-residential-chart {
        @apply w-full h-[260px] flex items-center mx-auto;
      }
    }
  }

  .starting-prices {
    .starting-prices-body {
      @apply flex flex-row mt-4;
      .starting-prices-body-details {
        @apply flex flex-col w-1/2 h-full;
        .starting-prices-body-title {
          @apply text-lg text-base-color-light;
        }
        .starting-prices-total-sum {
          @apply text-2xl font-medium text-base-color-light;
        }
        .starting-prices-of-objects {
          @apply flex flex-col mt-3;
          .object-item-wrapper {
            @apply p-3 rounded-md text-base-color-light;
            .object-item-body {
              @apply flex items-center justify-between text-sm leading-3 text-gray-700 w-full;
              .item-title {
                @apply flex items-center;
                .item-indicator {
                  @apply w-3 h-3 rounded-sm mr-1;
                  background: var(
                    --current-indicator-bg-color,
                    var(--base-color-light)
                  );
                }
              }
            }
            &.item-even {
              @apply bg-gray-200 bg-opacity-50;
            }
            &:hover {
              @apply outline outline-2 outline-base-color-light;
            }
          }
        }
      }
      .starting-prices-chart {
        @apply w-1/2 h-[240px] flex items-center justify-center mx-auto;
      }
    }
  }

  .sale-of-homes {
    .sale-of-homes-body {
      @apply mt-4;
      .total-sales {
        @apply w-full text-green-700 bg-green-100 rounded-lg border-2 border-green-700 border-solid p-4;
        .sales-number {
          @apply mr-2 text-5xl font-medium;
        }
      }
      .sales-wrapper {
        @apply mt-4;
        .sale-item {
          @apply flex items-center justify-between w-full my-2 p-2 rounded-lg text-base-color bg-base-color-light bg-opacity-10 border-2 border-base-color-light border-dashed;
          .object-name {
            @apply w-1/2 text-line-1;
          }
          .divider {
            @apply mx-2;
          }
          .sale-count {
            @apply w-1/2;
            & span {
              @apply font-medium;
            }
          }
        }
      }
    }
  }

  .average-price-for-square {
    .average-price-for-square-body {
      @apply mt-3;
      .average-price-for-square-item {
        @apply flex-col items-start w-full p-3 border-2 border-base-color-light border-solid rounded-lg text-base-color-light;
        .object-title {
          @apply flex items-center font-medium text-base-color-light;
        }
        .average-prices-wrapper {
          @apply mt-2 w-full;
          .price-item {
            @apply flex items-center justify-between text-sm text-gray-700;
            .value {
              @apply font-medium;
            }
          }
        }
      }
    }
  }
}

/*             boss-residential-complex-wrapper style                  */

/*             boss-arrears-wrapper style                  */

.boss-arrears-wrapper {
  @apply p-4;

  .arrears-actions-wrapper {
    @apply flex items-center justify-end mb-3;
    .arrears-actions-body {
      @apply flex items-center justify-between w-full;
    }
  }

  .arrears-chart {
    @apply my-shadow-1 rounded-lg p-6 mb-6;
    .chart-header {
      @apply flex items-center justify-between mb-3;
      .chart-title {
        @apply text-center text-xl capitalize leading-6 font-medium flex items-center flex-col;
        .chart-total-sum {
          @apply text-base leading-6 font-normal text-gray-600;
        }
      }
    }
  }

  .arrears-table {
  }
}

/*             boss-arrears-wrapper style                  */

/*             boss-sale-wrapper style                  */

.boss-sale-wrapper {
  @apply p-4;

  .sale-actions-wrapper {
    @apply flex items-center justify-end mb-3;
    .sale-actions-body {
      @apply flex items-center justify-between w-full;
    }
  }

  .sale-chart {
    @apply my-shadow-1 rounded-lg p-6 mb-6;
    .chart-header {
      @apply flex items-center justify-between mb-3;
      .chart-title {
        @apply text-center text-xl capitalize leading-6 font-medium flex items-center w-full flex-col;
        .chart-total-sum {
          @apply text-base leading-6 font-normal text-gray-600;
        }
      }
    }
  }
}

/*             boss-sale-wrapper style                  */

/*             boss-contract-wrapper style                  */

.boss-contract-wrapper {
  @apply p-4 h-full;
  .boss-contract-item {
    @apply my-shadow-1 rounded-lg h-full p-3;
    .boss-contract-item-header {
      @apply flex items-center justify-between;
      .boss-contract-item-title {
        @apply text-gray-700 leading-4 px-1;
      }
    }
    .boss-contract-item-actions-wrapper {
      @apply flex items-center max-w-[250px] mt-2;
    }

    &.client-contracts {
      .client-contracts-body {
        @apply py-4 h-full;
        .clients-contract-wrapper {
          @apply overflow-y-auto h-full p-1;
          scrollbar-gutter: auto;

          .client-accordion {
            @apply mb-4;
            .client-accordion-summary {
              @apply my-shadow-1 min-h-[32px] px-2;
              .client-data {
                @apply flex items-center justify-between w-full py-2;
                .client-data-header {
                  @apply flex items-center;
                  .client-icon {
                    @apply w-10 h-10 min-w-[40px] min-h-[40px] rounded-full mr-2 text-xl text-base-color-light flex items-center justify-center border-2 border-base-color-light border-solid;
                  }
                  .client-data-title {
                    @apply flex flex-col mr-2;
                    .client-name {
                      @apply text-[15px] font-medium leading-5 text-base-color;
                    }
                    .client-phone {
                      @apply text-[12px] text-gray-700;
                    }
                  }
                }

                .client-contracts-number {
                  @apply text-sm font-bold bg-base-color-light text-white rounded w-6 h-6 flex items-center justify-center mr-2;
                }
              }
            }
            .client-accordion-details {
              @apply px-0;
              .client-contracts-wrapper {
                @apply px-2 relative;
                .client-contract-item-wrapper {
                  @apply flex flex-col my-shadow-1 p-3 rounded-lg mb-4;
                  .item-details {
                    @apply flex items-center justify-between text-sm relative;
                    .label {
                      @apply text-gray-700 w-1/2;
                    }
                    .value {
                      @apply font-medium w-1/2 text-end;
                    }
                  }
                  &:first-child {
                    @apply mt-2;
                  }
                  &:last-child {
                    @apply mb-0;
                  }
                }
                &::before {
                  content: '';
                  @apply absolute left-0 -top-4 rounded-lg rounded-t-none w-full h-[calc(100%+24px)] border-2 border-dashed border-base-color-light border-t-transparent;
                }
              }
            }

            &::before {
              content: '';
              display: none;
            }

            &.Mui-expanded {
              @apply m-0;
            }
          }

          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }

          &:hover,
          &:focus,
          &:active {
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--base-color-light-separators), 0.5);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: rgba(var(--base-color-light-separators), 0.9);
          }

          &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: var(--base-color-light-hover);
          }
        }
      }
    }

    &.contract-terms {
      @apply h-full;
      .contract-terms-body {
        @apply overflow-y-auto h-[calc(100%-74px)] p-1;
        scrollbar-gutter: auto;
        .contracts-term-accordion {
          @apply mb-4;
          .contracts-term-accordion-summary {
            @apply min-h-[32px] px-2 text-white text-sm font-medium;

            &.from-0-to-24 {
              @apply bg-red-500  shadow-red-500 custom-shadow-5|1;
            }

            &.from-24-to-60 {
              @apply bg-orange-500 text-white shadow-orange-500 custom-shadow-5|1;
            }

            &.more-than-60 {
              @apply bg-base-color-light text-white shadow-base-color-light custom-shadow-5|1;
            }

            .expand-icon {
              @apply text-white;
            }

            .contracts-term-data {
              @apply flex items-center w-full py-2;
              .contract-range {
                @apply ml-2;
              }
              .divider {
                @apply h-8 w-[1.5px] bg-white mx-2;
              }
              .contracts-number {
                @apply mx-2;
              }
            }
          }
          .contracts-term-accordion-details {
            @apply px-1 mt-2;
            .contracts-wrapper {
              .contracts-wrapper-accordion {
                @apply mb-4;
                .contracts-wrapper-accordion-summary {
                  @apply min-h-[32px] px-2 flex text-sm;
                  &.from-0-to-24 {
                    @apply bg-red-50 shadow-red-400 custom-shadow-[3|1] border-2 border-dashed border-red-400;
                    .contract-month {
                      @apply text-red-700;
                    }
                    .contracts-number {
                      @apply bg-red-700 text-white;
                    }
                    .expand-icon {
                      @apply text-red-700;
                    }
                  }
                  &.from-24-to-60 {
                    @apply bg-orange-50 shadow-orange-400 custom-shadow-[3|1] border-2 border-dashed border-orange-400;
                    .contract-month {
                      @apply text-orange-700;
                    }
                    .contracts-number {
                      @apply bg-orange-700 text-white;
                    }
                    .expand-icon {
                      @apply text-orange-700;
                    }
                  }
                  &.more-than-60 {
                    @apply bg-base-color-light-f-15 shadow-base-color-light custom-shadow-[3|1] border-2 border-dashed border-base-color-light;
                    .contract-month {
                      @apply text-base-color-light;
                    }
                    .contracts-number {
                      @apply bg-base-color-light text-white;
                    }
                    .expand-icon {
                      @apply text-base-color-light;
                    }
                  }
                  .contracts-child-data {
                    @apply flex items-center justify-between w-full py-2;
                    .contracts-child-data-header {
                      @apply flex items-center;
                      .contracts-child-data-title {
                        @apply ml-2;
                        .contract-month {
                          @apply font-semibold;
                        }
                      }
                    }
                    .contracts-number {
                      @apply font-bold rounded w-6 h-6 flex items-center justify-center mr-2;
                    }
                  }
                }
                .contracts-wrapper-accordion-details {
                  @apply px-0 mt-2;
                }

                &::before {
                  content: '';
                  display: none;
                }

                &.Mui-expanded {
                  @apply m-0;
                }
              }
            }
          }

          &::before {
            content: '';
            display: none;
          }

          &.Mui-expanded {
            @apply m-0;
          }
        }

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }

        &:hover,
        &:focus,
        &:active {
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(var(--base-color-light-separators), 0.5);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: rgba(var(--base-color-light-separators), 0.9);
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background: var(--base-color-light-hover);
        }
      }
    }
  }

  .MuiGrid-item {
    @apply h-[calc(100vh-96px)] pb-3 relative;
  }
}

/*             boss-contract-wrapper style                  */

/*             payment-term-modal-wrapper style                  */

.payment-term-modal-wrapper {
  @apply w-[450px] relative;
  .items-wrapper {
    @apply w-full flex flex-col;
    .item-data {
      @apply my-2 my-shadow-1 rounded-lg p-3;
      .item-row {
        @apply flex items-center justify-between text-[15px] leading-5;
        .item-label {
          @apply text-gray-500;
        }
        .item-value {
        }
      }
    }
  }
}

/*             payment-term-modal-wrapper style                  */

/*             sale-of-homes-by-object-modal-wrapper style                  */

.sale-of-homes-by-object-modal-wrapper {
  .header-details {
    @apply flex items-center;
    .header-details-item {
      @apply text-green-700 bg-green-100 rounded-lg border-2 border-green-700 border-solid px-3 py-2;

      &.all-homes {
        @apply w-full flex items-center justify-between;
        .object-name {
          @apply text-xl font-medium text-line-1;
        }
      }

      &.in-sale-homes {
        @apply flex;
        .divider {
          @apply h-auto w-[1px] mx-4 bg-green-700;
        }
      }

      &:not(:first-of-type) {
        @apply ml-2;
      }
    }
  }

  .body-details {
    @apply mt-3;
    .block-sale-homes-item {
      @apply p-2 my-shadow-1 rounded-lg;
      .item-header {
        @apply flex items-center justify-between text-base-color;
        .block-name {
          @apply text-sm font-medium;
        }
        .item-total-homes {
          @apply text-[12px] relative;
          span {
            @apply font-semibold;
          }
        }
      }
      .item-body {
        @apply flex items-center mt-2;
        .item-sale-homes {
          @apply text-base-color-light bg-base-color-light-f-15 rounded-lg px-3 py-2;
          &:not(:first-of-type) {
            @apply ml-2;
          }

          &.in-sale-homes {
            @apply flex;
            .divider {
              @apply h-auto w-[1px] mx-4 bg-base-color-light;
            }
          }
        }
      }
    }
  }

  .homes-number {
    @apply flex flex-col items-center justify-center;
    .label {
      @apply text-[12px] relative;
    }
    .value {
      @apply text-3xl font-bold;
    }
  }

  .homes-by-repair {
    @apply flex flex-col items-center justify-center;
    .homes-by-repair-item {
      @apply text-sm flex items-center w-full;
      span {
        @apply ml-1 font-semibold;
      }
    }
  }
}

/*             sale-of-homes-by-object-modal-wrapper style                  */

/*             income-details-by-residential-complex-modal-wrapper style                  */

.income-details-by-residential-complex-modal-wrapper {
  @apply flex items-center mt-2;
  .progress-loader {
    @apply w-full h-[100px] flex items-center justify-center;
  }

  .total-details {
    @apply my-shadow-1 rounded-lg p-2;
  }

  .income-details-by-block-item {
    @apply my-shadow-1 rounded-lg p-2;
    &:not(:first-of-type) {
      @apply mt-4;
    }
  }

  .block-name {
    @apply text-base-color-light font-medium;
  }

  .in-sale-price,
  .sold-price {
    @apply flex items-center justify-between font-medium;
  }

  .paid-price,
  .waiting-price {
    @apply flex items-center justify-between text-gray-700;
  }
}

/*             income-details-by-residential-complex-modal-wrapper style                  */

/*             objects-button-box-wrapper style                  */

.objects-button-box-wrapper {
  @apply -mx-1 flex items-center flex-wrap w-full;
  .object-item {
    @apply h-8 min-w-[32px] min-h-[32px] max-w-max px-3 m-[5px] text-sm leading-3;
  }
}

/*             objects-button-box-wrapper style                  */

/*             minimap-wrapper style                  */

.minimap-wrapper {
  @apply absolute bottom-4 right-6 h-16;
  .viewer {
    @apply border-[3px] border-solid border-base-color-disabled absolute w-0 h-[calc(100%-8px)] cursor-move m-1 transition-[border] rounded-lg;
    &:hover {
      @apply border-base-color-light;
    }
  }
  .items-wrapper {
    @apply w-full h-full flex items-center rounded-lg bg-gray-100 p-2;
    .item {
      @apply h-full bg-base-color-light-f-15 rounded min-w-[20px] mr-1 last:mr-0 flex items-center justify-center select-none pointer-events-none;
    }
  }
}

/*             minimap-wrapper style                  */

/*             images-swiper-wrapper style                  */

.images-swiper-wrapper {
  @apply h-full w-full relative;
  .swiper {
    @apply w-full h-full;
  }
  .swiper-zoom-container > img,
  .swiper-zoom-container > svg,
  .swiper-zoom-container > canvas {
    @apply w-full h-full;
  }

  .swiper-navigation-button-wrapper {
    @apply absolute -translate-y-1/2 top-1/2 p-2 z-10;
    &.prev {
      @apply left-5;
    }
    &.next {
      @apply right-5;
    }
    &.is-disabled {
      @apply opacity-50;
    }
  }

  .swiper-pagination-wrapper {
    @apply absolute bottom-2 z-10 flex justify-center w-full;
    .swiper-pagination-details {
      @apply bg-white text-base-color text-sm my-shadow-1 rounded px-1.5 py-0.5;
    }
  }

  /* .swiper-zoom-buttons-wrapper {
		@apply absolute h-14 bg-black bg-opacity-20 bottom-0 w-full flex items-center justify-center z-10;
	} */
}

/*             images-swiper-wrapper style                  */

/*             file-upload style                  */

#form-file-upload {
  @apply h-32 w-full max-w-full text-center relative;
}

#input-file-upload {
  @apply hidden;
}

#label-file-upload {
  // background-color: rgba(var(--base-color-light-separators), 0.05);
  @apply h-full flex items-center justify-center border-2 rounded-lg border-dashed border-base-color-hover;
}

#label-file-upload.drag-active {
  @apply border-base-color bg-base-2;
}

#drag-file-element {
  @apply absolute w-full h-full top-0 right-0 left-0 bottom-0 rounded-lg;
}

/*             file-upload style                  */

/*             media query styles                  */

@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 640px) {
}

@media screen and (min-width: 475px) {
}

/*             media query styles                  */
